<template>
    <v-dialog v-model="showForm" fullscreen persistent hide-overlay transition="dialog-bottom-transition" @keydown.esc="closeFormScreen">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeFormScreen">
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Gestão do pagamento</v-toolbar-title>
            </v-toolbar>
            <v-container grid-list-xl fluid>
                <v-alert border="left" colored-border type="info" elevation="5" v-if="data">
                    <v-row>
                        <v-col cols="12">
                            <h2>Informações</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <strong>Identificador: </strong>{{ id }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <strong>Carregador: </strong>{{ data.charger }}
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Plug: </strong>{{ data.plug }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <strong>Data: </strong>{{ data.date }}
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Operadora: </strong>{{ data.operator }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <strong>Valor: </strong> R$ {{ data.value }}
                        </v-col>
                        <v-col cols="12" sm="6">
                            <strong>Status: </strong>{{ data.status }}
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert border="left" colored-border type="warning" elevation="5" v-if="data">
                    <v-row>
                        <v-col cols="12">
                            <h2>Operações</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field v-model="valueToReturn" :rules="[rules.money]" label="Valor para capturar" v-mask="['#.##', '##.##', '###.##']" return-masked-value prefix="R$"
                                type="number" required />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-btn block tile color="error" @click="returnPartialPayment"> <v-icon left>attach_money</v-icon> Captura Parcial </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block tile color="error" @click="cancelPayment"> <v-icon left>attach_money</v-icon> Cancelamento de captura </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block tile color="error" @click="refundPayment"> <v-icon left>attach_money</v-icon> Estorno do pagamento </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn block tile color="success" @click="updatePayment"> Buscar informações </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
                <v-alert border="left" colored-border type="warning" elevation="5" v-if="moreInfo || (data && data.infoOnInsert)">
                    <v-row>
                        <v-col cols="12">
                            <h2>Informações da Operadora do cartão</h2>
                        </v-col>
                    </v-row><v-row>
                        <v-col cols="12">

                            <ExpandPanelSimple title="Primeiro status" v-if="data && data.infoOnInsert">
                                <pre>{{ data.infoOnInsert }}</pre>
                            </ExpandPanelSimple>
                            <ExpandPanelSimple title="Última atualização" v-if="moreInfo">
                                <pre>{{ moreInfo }}</pre>
                            </ExpandPanelSimple>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-container>
        </v-card>
        <ConfirmModal ref="confirm" />
    </v-dialog>
</template>

<script>
import { mask } from "vue-the-mask";
import ConfirmModal from "@/components/core/ConfirmModal";
import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    components: { ConfirmModal, ExpandPanelSimple },

    props: {
        id: {
            type: Number,
        },
    },

    data() {
        return {
            rules,
            data: null,
            showForm: true,
            moreInfo: null,
            valueToReturn: null,
        }
    },

    watch: {
        id: {
            immediate: true,
            handler(newValue) {
                this.showForm = newValue != undefined;
                this.get();
            },
        },
    },

    mounted() {
        this.get();
    },

    methods: {
        closeFormScreen() {
            this.showForm = false;
            this.$emit("close");
        },

        openFormScreen() {
            this.showForm = true;
        },

        get() {
            if (this.id) {
                this.$http
                    .get(`api/v2/payment/${this.id}`)
                    .then((result) => {
                        this.data = result;
                        if (result.moreInfo) {
                            this.moreInfo = result.moreInfo;
                        }
                    })
                    .catch(() => {
                        this.data = null;
                        this.moreInfo = null;
                    });
            } else {
                this.data = null;
            }
        },

        returnPartialPayment() {
            if (Number.parseFloat(this.valueToReturn) <= Number.parseFloat(this.data.value)) {
                this.$refs.confirm
                    .open("Tem certeza?", "Será feita a tentativa de captura do valor, não será possível desfazer ação.")
                    .then((confirm) => {
                        if (confirm) {
                            this.$http
                                .postAlternativeAPI("api/v2/payment", { id: this.id, action: "FINISH", value: this.valueToReturn })
                                .then(() => {
                                    this.get();
                                })
                                .catch((error) => {
                                    this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                                });
                        }
                    });
            } else {
                this.$eventHub.$emit("msgError", `Valor deve ser menos que o valor capturado inicialmente. (${this.valueToReturn} <= ${this.data.value})`);
            }
        },

        updatePayment() {
            this.$http
                .postAlternativeAPI("api/v2/payment", { id: this.id, action: "get" })
                .then((result) => {
                    if (result) {
                        this.moreInfo = result;
                    }
                })
                .catch((error) => {
                    this.moreInfo = null;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                });
        },

        cancelPayment() {
            this.$refs.confirm
                .open("Tem certeza?", "Será feita a tentativa cancelamento da reserva, não será possível desfazer ação.")
                .then((confirm) => {
                    if (confirm) {
                        this.$http
                            .postAlternativeAPI("api/v2/payment", { id: this.id, action: "CANCELED" })
                            .then(() => {
                                this.get();
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                            });
                    }
                });
        },

        refundPayment() {
            this.$refs.confirm
                .open("Tem certeza?", "Será feita a tentativa de estorno, não será possível desfazer ação.")
                .then((confirm) => {
                    if (confirm) {
                        this.$http
                            .postAlternativeAPI("api/v2/payment", { id: this.id, action: "REFUND" })
                            .then(() => {
                                this.get();
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                            });
                    }
                });
        }
    },
};
</script>